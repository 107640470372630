<script setup lang="ts">
import { CmsElementProductListing } from "@shopware-pwa/composables-next";

const props = defineProps<{
  content: CmsElementProductListing;
}>();

const { getElements, setInitialListing, getCurrentPage, changeCurrentPage, getTotalPagesCount } = useListing({
  listingType: "categoryListing",
  defaultSearchCriteria: {
    limit: 24,
  }
});

const route = useRoute();
const router = useRouter();

const loading = ref(false);

const changePage = async (page: number) => {
  loading.value = true;
  window.scrollTo(0, 0);

  await router.push({
    query: {
      ...route.query,
      p: page,
    },
  });

  changeCurrentPage(page, route.query).then(() => {
    loading.value = false;
  });
};

const isProductListing = computed(() => props.content?.type === "product-listing");

setInitialListing(props?.content?.data?.listing);
</script>

<template>
  <div>
    <div v-if="loading" class="fixed inset-0 z-2 flex items-center justify-center bg-maas-background-default/60 backdrop-blur-lg">
      <span class="spinner"></span>
    </div>

    <SharedFeed :products="getElements" :isProductListing="isProductListing" type="stacked" loading="eager">
      <SharedPagination :total="getTotalPagesCount" :current="Number(getCurrentPage)" @change-page="changePage" />
    </SharedFeed>
  </div>
</template>
